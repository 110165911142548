<template>
  <div>
    <b-row
      class="d-flex align-items-center problem-card-header"
      align-h="between"
    >
      <b-col
        class="score m-auto"
        lg="6"
        xl="4"
      >
        <div
          style="position: relative; left: -15px;"
        >
          <b-button
            size="small"
            variant="flat-secondary"
            class="btn-icon text-white"
          >
            <feather-icon
              size="25"
              role="button"
              icon="ArrowLeftIcon"
              style="margin-bottom: 5px"
              @click="$router.push(fromGuest ?
                {
                  name: 'demo-lesson',
                } :
                {
                  name: `${self.usertype}-lesson`,
                  params: { type: $route.params.type },
                  query: { mode: $route.query.mode, event_id: $route.query.event_id }
                })"
            />
          </b-button>
        </div>
        <div v-if="!hasMinimalView"
             class="w-100"
        >
          <table class="score-table mb-2">
            <tr>
              <td class="pl-1 swiper-wrapper">
                <!-- Slider Starts  -->
                <vue-agile
                  v-if="scoreData && !hideHighScore"
                  ref="slider"
                  :options="swiperOptions"
                  :nav-buttons="true"
                  :swipe-distance="300"
                  class="score-swipe justify-content-center align-items-center"
                >
                  <!-- Highest Score  -->
                  <div class="slide">
                    <div class="d-flex justify-content-center">
                      <div class="ml-1">
                        <p
                          class="m-0 text-nowrap"
                        >
                          {{ $t('engine-page.highest') }}
                        </p>
                        <p class="m-0 text-nowrap">
                          {{ $t('engine-page.your-points') }}
                        </p>
                      </div>

                      <div class="ml-1 text-left">
                        <div
                          class="text-left"
                        >
                          <span v-if="scoreData.points_gainer?.max.points">
                            <span>{{ scoreData.points_gainer?.max.points ? scoreData.points_gainer?.max.points : 0 }} &nbsp;</span>
                            <span class="student-name">
                              <span>(</span>
                              <span
                                :class="{ 'no-underline': showMenu, 'dropdown-title': !isCurrentUser('points_gainer')}"
                                @click="!isCurrentUser('points_gainer') ? toggleMenu(scoreData.points_gainer?.max.user_id) : null"
                              >
                                {{ getFirstName(scoreData.points_gainer?.max.student) }}
                              </span>
                              <span>)</span>
                            </span>
                          </span>
                          <span v-else>{{ $t('engine-page.no-highscore') }}</span>
                        </div>
                        <div class="text-left">
                          <span>{{ scoreData.points_gainer?.my.points ? scoreData.points_gainer?.my.points : 0 }}&nbsp;</span>
                          <span class="student-name">{{ `( ${self.firstname} )` }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- First Finished  -->
                  <div class="slide">
                    <div class="d-flex justify-content-center">
                      <div class="ml-1">
                        <p class="m-0 text-nowrap">
                          {{ $t('engine-page.first-finisher') }}
                        </p>
                        <p
                          v-if="scoreData.first_done?.my.position"
                          class="m-0 text-nowrap"
                        >
                          {{ $t('engine-page.your-place') }}
                        </p>
                        <p
                          v-else
                          class="m-0 text-nowrap"
                        >
                          {{ $t('engine-page.your-possible-place') }}
                        </p>
                      </div>

                      <div class="ml-1 text-left">
                        <div
                          v-if="scoreData.first_done?.max.student"
                          class="text-left"
                        >
                          <span>1&nbsp;</span>
                          <span class="mb-0 student-name">
                            <span>(</span>
                            <span
                              :class="{ 'no-underline': showMenu, 'dropdown-title': !isCurrentUser('first_done')}"
                              @click="!isCurrentUser('first_done') ? toggleMenu(scoreData.first_done?.max.user_id) : null"
                            >
                              {{ getFirstName(scoreData.first_done?.max.student) }}
                            </span>
                            <span>)</span>
                          </span>
                        </div>
                        <div
                          v-else
                          class="text-left"
                        >
                          <span>{{ $t('engine-page.no-first-finisher') }}</span>
                        </div>
                        <div class="text-left">
                          <span v-if="scoreData.first_done?.my.position">{{ scoreData.first_done?.my.position }}&nbsp;</span>
                          <span v-else>{{ scoreData.first_done?.my.possibility }}</span>
                          <span class="student-name">{{ `( ${self.firstname} )` }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Most Lessons Achieved  -->
                  <div class="slide">
                    <div class="d-flex justify-content-center">
                      <div class="ml-1">
                        <p class="m-0 text-nowrap">
                          {{ $t('engine-page.top-achiever') }}
                        </p>
                        <p class="m-0 text-nowrap">
                          {{ $t('engine-page.you-completed') }}
                        </p>
                      </div>

                      <div class="ml-1 text-left">
                        <div
                          v-if="scoreData.most_done?.max.student"
                          class="text-left"
                        >
                          <span>{{ scoreData.most_done?.max.lesson_completed }}&nbsp;</span>
                          <span class="student-name">
                            <span>(</span>
                            <span
                              :class="{ 'no-underline': showMenu, 'dropdown-title': !isCurrentUser('most_done')}"
                              @click="!isCurrentUser('most_done') ? toggleMenu(scoreData.most_done?.max.user_id) : null"
                            >
                              {{ getFirstName(scoreData.most_done?.max.student) }}
                            </span>
                            <span>)</span>
                          </span>
                        </div>
                        <div
                          v-else
                          class="text-left"
                        >
                          {{ $t('engine-page.no-top-achiever') }}
                        </div>
                        <div class="text-left">
                          <span>{{ scoreData.most_done?.my.lesson_completed ? scoreData.most_done?.my.lesson_completed : 0 }} &nbsp;</span>
                          <span class="student-name">{{ `( ${self.firstname} )` }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Navigation Buttons  -->
                  <template slot="prevButton">
                    <feather-icon
                      slot="button-prev"
                      class="button-prev"
                      size="24"
                      icon="ChevronLeftIcon"
                    />
                  </template>

                  <template slot="nextButton">
                    <feather-icon
                      slot="button-next"
                      class="button-next"
                      size="24"
                      icon="ChevronRightIcon"
                    />
                  </template>
                </vue-agile>

                <div
                  v-if="!self"
                  :class="{'ml-2': !fromGuest}"
                >
                  <table class="score-table">
                    <tr>
                      <td>
                        <span class="text-nowrap">{{ $t('engine-page.yours') }}</span>
                      </td>
                      <td class="pl-1">
                        <span class="text-left  score-text score high">{{ totalPassed * passReward }}</span>
                      </td>
                    </tr>
                  </table>
                </div>
                <!-- Slider Ends  -->

                <!-- Context Menu  -->
                <div
                  v-if="showMenu"
                  v-on-clickaway="hideMenu"
                  class="dropdown-items"
                >
                  <b-nav
                    vertical
                    class="w-100"
                  >
                    <b-nav-item
                      class="hoverable py-1 pl-1"
                      @click="setActiveChat"
                    >
                      <feather-icon
                        icon="MessageCircleIcon"
                        size="18"
                      />
                      {{ $t('class-module.click-to-chat') }}
                    </b-nav-item>
                    <!-- Stickers  -->
                    <span class="p-0 sticker-wrapper">
                      <p class="mb-0 py-1 pl-1">
                        <feather-icon
                          icon="TwitchIcon"
                          size="18"
                        />
                        {{ $t('class-module.send-vibes') }}
                      </p>
                      <sticker-picker
                        height="260px"
                        width="200px"
                        :md="6"
                        :searchable="false"
                        :show-top-categories="false"
                        :show-categories="false"
                        :show-current-category="false"
                        :vibes="vibes"
                        category-tooltip-placement="bottom"
                        @select="sendSticker"
                      />
                    </span>

                    <!-- Sound effect when sticker is sent -->
                    <audio
                      ref="stickerAudio"
                      src="/sfx/sticker-send.mp3"
                    />
                  </b-nav>
                </div>
              </td>
            </tr>
          </table>
          <div>
            <b-badge v-if="lessonPdf && !hidePdf"
                     variant="primary"
                     tag="a"
                     :href="lessonPdf"
                     target="_blank"
            >
              <feather-icon
                icon="LinkIcon"
                class="mr-25"
              />
              <span>Lesson PDF</span>
            </b-badge>
          </div>
        </div>
      </b-col>
      <b-col
        lg="4"
        class="center-part"
      >
        <div v-if="!hasMinimalView">
          <div class="text-center ml-3">
            <div style="font-size: 16px !important">
              {{ lessonGroupName }}
            </div>
            <strong class="text-bold">{{ lessonName }}</strong>
            <span v-if="isHighLevelUser"
                  class="mr-2"
            >
              <b-button
                v-b-tooltip.hover
                :title="`Reset Practice`"
                variant="flat-info"
                style="color: rgb(247, 0, 0);"
                class="btn btn-icon btn-sm"
                @click="$emit('nextProblem', {reset:true})"
              >
                <feather-icon icon="RepeatIcon"
                              size="25"
                />
              </b-button>
            </span>
          </div>
          <div
            v-if="!isFollowUp"
            class="text-muted text-center ml-1"
          >
            <strong> {{ $t('student-test-module.problem') }} : {{ problemName }} </strong>
          </div>
          <div
            v-if="skillType"
            class="text-center ml-1"
          >
            <strong> {{ $t('labels.create-problem.skill-type') }}: {{ skillType }} </strong>
          </div>
          <div class="ml-1 w-100 points-list text-center justify-content-md-center">
            <template
              v-if="navigationSelected === 'dot'"
            >
              <span v-for="prog, indexCount of progressInfo"
                    :key="prog.id"
                    class="text-dark"
                    :role="'button'"
              >
                <feather-icon
                  v-bind="{ ...( prog.label != 'Q' ? {fill: prog.label === 'P' ? '#FFC107' : 'red'} : (activeProblemId === prog.id && {fill: 'rgb(33,32,95)'}) ) }"
                  size="16"
                  :icon="pointIcon"
                  badge-classes="bg-danger text-dark"
                  @click="handlePreview(indexCount)"
                />
              </span>
            </template>

            <template v-else>
              <div class="">
                <feather-icon
                  v-if="currentIndex > 0"
                  size="32"
                  icon="ArrowLeftCircleIcon"
                  :role="'button'"
                  class="mr-2"
                  @click="navigateProblem(currentIndex - 1)"
                />
                <feather-icon
                  v-if="currentIndex < (progressInfo.length-1)"
                  size="32"
                  icon="ArrowRightCircleIcon"
                  :role="'button'"
                  @click="navigateProblem(currentIndex + 1)"
                />

              </div>
            </template>
            <!-- <span
              v-for="i,indexCount in progress.total"
              :key="i"
              class="text-dark"
              :role="'button'"
              @click="handlePreview(indexCount)"
            >
              <feather-icon
                v-bind="{ ...( i <= totalPassed ? {fill: '#FFC107'} : (loadedIndex === indexCount && {fill: 'rgb(33,32,95)'}) ) }"
                size="16"
                :icon="pointIcon"
                badge-classes="bg-danger text-dark"
              />
            </span> -->
          </div>
        </div>
        <div v-if="loaderId && totalTestTime"
             class="text-center"
        >
          <LessonTimer :key="lessonTimeSpent + loaderId"
                       :loader-id="loaderId"
                       :total-test-time="totalTestTime"
                       :lesson-time-spent="lessonTimeSpent"
          />
        </div>
      </b-col>
      <b-col
        v-if="progress.total != 0 && showProgress"
        lg="12"
        xl="4"
        class=""
      >
        <span v-if="getLessonModeTag"
              class="float-right"
        >
          <b-badge :variant="getLessonModeTag.variant">
            {{ getLessonModeTag.text }}
          </b-badge>
        </span>
        <div class="d-flex justify-content-end align-items-center w-100">
          <div class="w-100">
            <span v-if="!hasMinimalView"
                  class="w-100 percentage-complete-bar"
            >

              <div class="text-right percentage-text">
                {{ $t('labels.passed') }}: <strong>{{ percentage }}%</strong></div>
            <!-- <div
              class="progress"
              :style="`box-shadow:${getGlowGradient}`"
            >
              <div
                class="progress-bar percentage-gradient"
                role="progressbar"
                :style="`width:${percentage}%;`"
                :aria-valuenow="percentage"
                aria-valuemin="0"
                :aria-valuemax="100"
              />
            </div> -->
            </span>
            <span
              v-if="!hideProgressBar"
              class="w-100"
            >
              <div
                class="progress"
                :style="`box-shadow:${getGlowGradient}`"
              >
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="`width:${getProgressPercentage}%;background: ${getLinearGradient};`"
                  :aria-valuenow="getCurrentProgress"
                  aria-valuemin="0"
                  :aria-valuemax="progress.total"
                />
              </div>
              <p
                style="margin-top: 4px"
                class="mb-2 text-right text-muted"
              >
                <strong>{{ $t('engine-page.current') }}</strong>
                <strong class="ml-2">{{ progress.current < progress.total ? progress.current + 1 : progress.current }}
                </strong>
                {{ $t("of") }} <strong>{{ progress.total }} </strong>
              </p>
            </span>
          </div>

          <!-- round flag -->
          <b-avatar
            v-if="!hasMinimalView"
            id="round-avatar"
            :variant="getFlagVariant"
            class="ml-1"
          >
            <span class="engine-flag">{{ round }}</span>
          </b-avatar>
          <b-tooltip target="round-avatar">
            {{ $t('labels.round') }}
          </b-tooltip>
        <!-- round flag -->
        </div>
      </b-col>
    </b-row>
    <question-preview
      :show="showPreview"
      :data="problemBeingPreviewed ? problemPreview[problemBeingPreviewed] :{}"
      :show-edit-mode="false"
      @close="showPreview=false"
    />
  </div>
</template>
<script>
/* eslint-disable */
import {
  BRow,
  BCol,
  BButton,
  BAvatar,
  BTooltip,
  BNav,
  BNavItem,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { USER_TYPE_TEACHER } from '@/const/userType'
import { isHighLevelUser } from '@/auth/utils'

import { VueAgile } from 'vue-agile'
import {POINT_ICON} from '@/const/badges'
import { getUserData , guestUserInfo } from '@/auth/utils'
import { mixin as clickaway } from 'vue-clickaway';
import StickerPicker from '@/views/common/components/StickerPicker.vue'
import store from '@/store'
import i18n from '@/libs/i18n'
import 'swiper/css/swiper.css'
import { mapActions, mapMutations } from 'vuex'
import QuestionPreview from '@/views/common/components/QuestionPreview.vue'
import LessonTimer from './LessonTimer.vue'
import { checkSettings, isVisible } from '@/utils/visibilitySettings'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAvatar,
    BTooltip,
    BNav,
    BNavItem,
    BBadge,
    // Swiper,
    // SwiperSlide,
    VueAgile,
    StickerPicker,
    QuestionPreview,
    LessonTimer
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      passReward: 5,
      pointIcon: POINT_ICON,
      problemPreview: {},
      showPreview: false,
      self: getUserData(),
      problemBeingPreviewed: 0,
      swiperOptions: {
        dots: false,
        slidesToShow: 1,
        speed: 500,
      },
      showMenu: false,
      selectedId: '',
      vibes: [],
      loadedIndex: -1,
      currentIndex: 0,
    }
  },
  mixins: [ clickaway ],
  props: {
    activeProblemId: {
      type: Number,
      default: 0
    },
    problemName: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'question',
    },
    progress: {
      type: Object,
      default: () => ({
        current: 0,
        total: 0,
      }),
    },
    showProgress: {
      type: Boolean,
      default: () => true,
    },
    lessonName: {
      type: String,
      default: '',
    },
    lessonGroupName: {
      type: String,
      default: '',
    },
    progressGlow: {
      type: Boolean,
      default: false,
    },
    isProgressException: {
      type: Boolean,
      default: false,
    },
    totalPassed: {
      type: Number,
      default: 0,
    },
    engine: {
      type: String,
      default: '',
    },
    percentage: {
      type: [Number, String],
      default: 0,
    },
    round: {
      type: [Number, String],
      default: 0,
    },
    progressInfo: {
      type: [Object, Array],
      default: () => []
    },
    scoreData: {
      type: [Object, Array],
      default: ()=> null,
    },
    pageLink: {
      type: [Object,String],
      default: ()=> null,
    },
    fromGuest: {
      type: Boolean,
      default: false,
    },
    skillType: {
      type: String,
      default: () => '',
    },
    lessonPdf: {
      type: String,
      default: '',
    },
    passedProblems: {
      type: Array,
      default: () => []
    },
    level: {
      type: Number,
      default: 0,
    },
    loaderId: {
      type: Number,
      default: 0,
    },
    hasMinimalView: {
      type: Boolean,
      default: false,
    },
    lessonTimeSpent: {
      type: Number,
      default: 0
    },
    totalTestTime: {
      type: [Object, String, Number],
      default: ''
    },
    schoolSettings: {
      type: Array,
      default: () => []
    },
    districtSettings: {
      type: Array,
      default: () => []
    },
    lessonMode: {
      type: String,
      default: 'normal'
    },
  },
  computed: {
    isFollowUp() {
      return this.lessonMode === 'follow_up'
    },
    getLessonModeTag() {
      switch (this.lessonMode) {
        case 'follow_up':  
        case 'follow_up_practice':                  
          return {
            text: this.$tc('calendar-module.follow-up'),
            variant: 'warning'
          }
        case 'class_test': {
          return {
            text: i18n.tc('student-diary-module.class-test'),
            variant: 'danger'
          }
        }
        default: {
          return {
            text:  i18n.tc('calendar-module.preview-lesson'),                          
            variant: 'primary'
          }
        }
      }
    },
    schoolSetting() {
        return this.$store.state.appConfig?.schoolInfo?.user_meta
    },
    distSetting() {
      return this.$store.state.appConfig?.districtInfo?.user_meta
    },

    getProgressPercentage() {
      return (this.getCurrentProgress / this.progress.total) * 100
    },
    getCurrentProgress() {
      return this.progress.current
    },
    getLinearGradient() {
      if (this.getProgressPercentage > 80) return 'linear-gradient(90deg, #2ed573 64%, rgba(46,204,113,1) 100%)'
      return 'linear-gradient(90deg, rgba(241,196,15,1) 0%, #f39c12 100%)'
    },
    getGlowGradient() {
      if (!this.progressGlow) return ''
      if (this.getProgressPercentage > 80) {
        return `0 0 5px #2ecc71,0 0 ${
          this.getProgressPercentage - 40
        }px rgba(46,204,113,1);`
      }
      return '0 0 5px rgba(241,196,15,1),0 0 10px #f39c12;'
    },
    getFlagVariant() {
      let variant = ''
      if (this.engine === 'adaptivepathengine') variant = 'success'
      else if (this.engine === 'carryforwardengine') variant = 'warning'
      else if (this.engine === 'linearstandaloneengine') variant = 'secondary'
      return variant
    },
    pageLinkWithDomain() {
      const link = this.pageLink
      if (!link) {
        return null
      }
      return (link.indexOf('://') === -1) ? 'http://' + link : link;
    },
    isHighLevelUser() {
      return isHighLevelUser() || this.isATeacher
    },
    isATeacher() {
      return this.AUTH_USER()?.usertype === USER_TYPE_TEACHER
    },
    hideProgressBar() {
      return !isVisible('progress_bar', this.schoolSetting, this.distSetting)
    },
    hideHighScore() {
      return !isVisible('high_score', this.schoolSetting, this.distSetting)
    },
    hidePdf() {
      return !isVisible('pdf', this.schoolSetting, this.distSetting)
    },
    navigationSelected() {
      const schoolSetting = this.$store.state.appConfig?.schoolInfo?.user_meta
      const distSetting = this.$store.state.appConfig?.districtInfo?.user_meta
      return checkSettings('problem_navigation', schoolSetting, distSetting, { schoolOverride: true })?.value ?? 'dot'
    }
  },
  watch: {
    totalPassed: function(v) {
      const current = Number(this.$store.state.problem.totalUserScore)
      const newScore = current + this.passReward
      this.$store.commit('problem/SET_CURRENT_OVERALL_SCORE',newScore)
    },
    activeProblemId(newVal) {
      const index = this.progressInfo.findIndex((prog) => prog.id === newVal)
      if (index !== -1) {
        this.currentIndex = index
      }
    },
  },
  // mounted() {
  //   isVisible('progress_bar').then(response => {
  //     this.hideProgressBar = !response
  //   })
  //   isVisible('high_score').then(response => {
  //     this.hideHighScore = !response
  //   })
  //   isVisible('pdf').then(response => {
  //     this.hidePdf = !response
  //   })
  // },
  methods: {
    ...mapActions('app-chat', ['sendMessage']),
    ...mapMutations('app-chat', {
      setActiveChatId: 'SET_ACTIVE_CHAT_ID',
    }),
    getTemplates(id) {
      if (!id) return
      // eslint-disable-next-line consistent-return
      return new Promise(resolve => {
        useJwt.getTemplate(id).then(res => {
          resolve(res.data.data)
        })
      })
    },
    loadNextProblem(index) {
      this.loadedIndex = index
      this.$emit('nextProblem', {
        load: index
      })
    },
    navigateProblem(index) {
      if (index >= 0 && index < this.progressInfo.length) {
        this.currentIndex = index
        console.log(this.currentIndex)
        this.handlePreview(index)
      }
    },
    handlePreview(index) {
      this.currentIndex = index
      const problem = this.progressInfo[index]
      if (problem.label === 'Q') {
        this.loadNextProblem(index)
        return
      }
      this.problemBeingPreviewed = problem.id
      useJwt.getProblem(problemId, {
        params: {
          loaderId: this.loaderId
        }
      }).then(async res => {
        const problemData = res.data.data
        const index = problemData.statLangLevel
        const statementAndFeedback = problemData.problem_statements[index-1]
        const problemTemplates = {
          [statementAndFeedback.fb_template_id]: await this.getTemplates(statementAndFeedback.fb_template_id),
          [statementAndFeedback.st_template_id]: await this.getTemplates(statementAndFeedback.st_template_id),
        }
        const answers = await useJwt.getAnswerPreview(problemData.testStatId)
        this.problemPreview[problemId] = {
          theme: problemData.theme ? problemData.theme : 0,
          problemType: problemData.problem_type.name,
          feedback: {
            type: statementAndFeedback.fb_type,
            text: statementAndFeedback.feedback,
            image: statementAndFeedback.fb_type === 'image' ? statementAndFeedback.fb_image : null,
            audio: statementAndFeedback.fb_type === 'audio' ? statementAndFeedback.fb_image : null,
            template: problemTemplates[statementAndFeedback.fb_template_id],
          },
          statement: {
            type: statementAndFeedback.st_type,
            text: statementAndFeedback.statement,
            image: statementAndFeedback.st_type === 'image' ? statementAndFeedback.st_image : null,
            audio: statementAndFeedback.st_type === 'audio' ? statementAndFeedback.st_image : null,
            template: problemTemplates[statementAndFeedback.st_template_id],
            video: problemData.video_time_stamp,
          },
          questions: problemData.questions.filter(e => e.lang_level == (index).toString()),
          answers: answers.data?.data,
          level: index,
        }
        this.showPreview = true
      })
    },
    toggleMenu(id = '') {
      if (!this.vibes.length) this.getVibes()
      this.selectedId = id
      this.showMenu = !this.showMenu
    },
    hideMenu() {
      if (this.showMenu) this.toggleMenu()
    },
    isCurrentUser(category) {
      return this.scoreData[category]?.max.user_id === this.self.id
    },
    setActiveChat() {
      store.dispatch('app-chat/getChat', { userId: this.selectedId }).then(response => {
        store.commit('app-chat/FETCH_CHAT_CONTACTS', {
          activeChat: response.data,
          profileUserDataMinimal: {
            avatar: null,
            id: response.data.contact?.id,
          },
          contacts: this.contacts,
        })
        store.commit('app-chat/SET_MINIMIZED', true) 
      })
      // this.$router.push({ name: 'chat' })
      setTimeout(() => {
        this.setActiveChatId({ id: this.selectedId, type: 'single'})
      }, 500)
    },
    getVibes() {
      useJwt.getVibes().then(res => {
        const vibes = res.data.data.reduce((groups, item) => {
          const category = (groups[item.category] || [])
          category.push(item)
          /* eslint-disable */
          groups[item.category] = category
          return groups
        }, {})
        if (Object.values(vibes).length) this.vibes.push(vibes)
      })
    },
    sendSticker(sticker) {
      let audio = ''
      let audioUrl = ''
      let payload = {
        contactId: this.selectedId,
        senderId: this.self.id,
        message: sticker.icon,
        code: sticker.code,
        type: 'sticker',
        audio,
        audioUrl,
      }
      this.sendMessage(payload).then(() => {
        this.$refs.stickerAudio.play()
        this.$swal({
          title: 'Sticker sent successfully!',
          icon: 'success',
          toast: true,
          showConfirmButton: false,
          position: 'bottom-start',
          timer: 3000,
        })
      })
    },
    getFirstName($name) {
      return $name.split(' ')[0]
    },
  },
}
</script>
<style lang="scss">
.student-problem .row {
  margin: 0;
}
  .go-to-chat {
    border-bottom: 1px solid #333;
    cursor: pointer;
    transition: all 0.2s ease-in;
  }
  .go-to-chat:hover {
    border-color: transparent;
  }
  .dropdown-items {
    background: rgba( 255, 255, 255, 0.75 ) !important;
    box-shadow: 0 2px 6px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    border-radius: 0 10px 0 10px;
  }
  .hoverable:first-of-type {
    border-top-right-radius: 10px;
  }
  .dropdown-items .sticker-container-wrapper {
    position: relative;
    top: 0 !important;
    width: 200px !important;
    padding: 0 8px;
  }
  .dropdown-items .sticker {
    margin-bottom: 24px !important;
  }
  .swiper-wrapper .agile__nav-button {
    position: absolute;
    top: 4px;
    border-color: transparent !important;
    background-color: transparent;
    color: #fff;
  }
  .sticker-wrapper {
    width: 200px !important;
  }
  .swiper-wrapper .agile__nav-button--prev {
    left: -24px;
  }
  .swiper-wrapper .agile__nav-button--next {
    right: -20px;
  }
  .sticker-wrapper p {
    font-weight: 500;
  }
  .swal2-container {
    min-width: 350px;
  }

  @media only screen and (max-width: 576px) {
    .swiper-wrapper .slide {
      width: 300px !important;
    }
    .swiper-wrapper {
      margin-left: -20px;
    }
    .swiper-wrapper .agile__nav-button--prev {
      left: 0;
    }
    .swiper-wrapper .agile__nav-button--next {
      // right: 0;
      right: 83px;
    }
    #pageLink {
      margin-top: 0px !important;
      position: relative !important;
      margin-right: -1em !important;
      padding-left: 10px !important;
      background: #8bc24a;
    }
  }

  @media only screen and (max-width: 992px) and (min-width: 576px) {
    .swiper-wrapper .slide {
      width: 400px;
    }
    .swiper-wrapper .agile__nav-button {
      top: 6px;
    }
    .swiper-wrapper .agile__nav-button--prev {
      left: 0;
    }
    .swiper-wrapper .agile__nav-button--next {
      right: 0;
    }
  }

  @media only screen and (max-width: 1200px) and (min-width: 992px) {
    .score-table {
      width: 100%;
    }
    .swiper-wrapper .slide {
      width: 500px;
    }
    .swiper-wrapper .score-swipe {
      width: 500px !important;
    }
    .swiper-wrapper .agile__nav-button {
      top: 6px;
    }
    .swiper-wrapper .agile__nav-button--prev {
      left: -8px;
    }
    .swiper-wrapper .agile__nav-button--next {
      right: -8px;
    }
    .slide .student-name {
      margin: 0 !important;
    }
    .center-part {
      margin: 0 18px 8px 0;
    }
  }

</style>
<style scoped lang="scss">
  .no-wrap {
    white-space: nowrap;
  }
  .first-row {
    background-color: #8bc34a;
  }
  .problem-card-header {
    margin-top: -12px;
  }
  .score {
    margin-left: -8px;
  }
  .progress {
    transition: all 500ms ease;
  }
  .progress {
    background: #fff;
  }
  .progress-bar {
    background: rgb(241, 196, 15);
  }
  .engine-flag {
    font-size: 18px;
    font-weight: 800 !important;
  }
  .percentage-complete-bar .progress {
    margin-bottom: 5px;
  }
  .percentage-complete-bar .percentage-text {
    padding: 3px 0;
  }
  .percentage-complete-bar .percentage-gradient {
    background: #ffeb3b;
  }
  .score-text {
    font-weight: bold;
    font-size: 16.5px;
  }
  .score-text.score {
    font-weight: bold;
    font-size: 18px;
  }
  .score-table {
    margin-left: 8px;
    margin: auto;
    td {
      border: none;
    }
  }
  .swiper-wrapper {
    position: relative;
  }
  .score-swipe {
    width: 400px;
    margin-left: -8px;
  }
  .slide {
    width: 400px;
  }
  .slide .student-name {
    margin: 0 4px;
  }
  .slide .text-right {
    padding: 0 8px;
  }
  .slide .text-left {
    padding-left: 0;
  }
  .dropdown-title {
    border-bottom: 1px solid;
    cursor: pointer;
  }
  .no-underline,
  .dropdown-title:hover {
    border-color: transparent;
  }
  .dropdown-items {
    position: absolute;
    width: 200px;
    top: 36px;
    right: 18px;
    background-color: #fff;
    z-index: 1;
  }
  .dropdown-items .nav-item {
    text-align: left;
    padding: 6px 12px;
    width: 100%;
  }
  .dropdown-items .hoverable {
    border-bottom: 1px solid #aaa;
    transition: all 0.3s ease-in;
  }
  .dropdown-items .hoverable:hover {
    background-color: #e6e6e6;
    cursor: pointer;
  }
  .dropdown-items .nav-link {
    color: #101010;
    font-weight: 500;
    padding: 0;
  }
  .sticker-wrapper {
    color: #333;
    height: 310px;
    width: 250px;
    padding-top: 6px;
  }
  .back-icon {
    padding: 6px;
    cursor: pointer;
  }
  .go-to-chat {
    cursor: pointer;
    text-decoration: underline;
  }
@media screen and (max-width: 992px) {
  .score-table,
  .my-points {
    margin: auto;
    td {
      border: none;
    }
  }
  .swiper-wrapper {
    position: relative;
  }
  .score-swipe {
    width: 300px;
    margin-left: -8px;
  }
  .my-points {
    margin-left: 4px;
  }
  .slide .student-name {
    margin: 0 4px;
  }
  @media screen and (max-width: 992px) {
    .score-table,
    .my-points {
      margin: auto;
      text-align: center;
    }
    .score-swipe {
      width: 400px !important;
    }
  }
  @media screen and (max-width: 1200px) {
    .score-swipe {
      width: 240px;
    }
  }
}

#pageLink {
  position:absolute;
  margin-top: 13px;
  z-index: 2;
  right:0;
  margin-right: 15px
}

.points-list{
  overflow:auto;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.dark-layout .points-list .text-dark {
  color: #4b4b4b !important
}
</style>
